/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import { format } from "date-fns";

import FigureGrid from "../components/figureGrid";
import Layout from "../components/layout";
import Section from "../components/section";
import Figure, { Banner } from "../components/figures";
import Description from "../components/description";
import List from "../components/lists";
import BlockText from "../components/blockText";
import ImageGrid from "../components/imageGrid";
import PdfDisplay from "../components/pdfDisplay";
import SEO from "../components/seo";

import pdf from '../assets/pdfs/GenericInvestmentStatement.pdf';

export const query = graphql`
    query CaseStudyTempleteQuery($id: String!) {
        caseStudy: sanityCaseStudy(id: { eq: $id }) {
            id
            title
            hook
            technology
            projectLink
            _rawObjective
            _rawSolution
            startDate
            banner {
                ...ImageWithPreview
            }
            breakImage {
                ...ImageWithPreview
            }
            steps {
                title
                _rawDescription
                _rawComponents
            }
        }
    }
`;

const Projects = props => {
    const { data, errors } = props;
    const project = data && data.caseStudy;
    console.log(project);
    return (
        <Layout>
            <SEO title={project.title || "Undefined"} />
            <Section name="hero" hero>
                <h3 sx={{ pb: 1, variant: "text.heading.sub" }}>{project.title}</h3>
                <h1 sx={{ pb: 1, variant: "text.title.main" }}>{project.hook}</h1>
                <p sx={{ variant: "text.body.alt" }}>{project.technology}</p>
                <Banner fullscreen imageInfo={project.banner} />
            </Section>
            <Section name="introduction">
                <div className="introduction-body" sx={{ display: ["block", "block", "flex"] }}>
                    <Description heading="The objective">
                        <BlockText blocks={project._rawObjective} />
                    </Description>
                    <Description heading="The solution" inList>
                        <BlockText blocks={project._rawSolution} />
                    </Description>
                </div>
                <div
                    className="introduction-information"
                    sx={{ display: ["block", "block", "block", "flex"], pt: [2, 3, 5] }}
                >
                    {project.projectLink && (
                        <div sx={{ pb: [2, 2, 2, 0], pr: [0, 0, 0, 5] }}>
                            <p sx={{ pb: 1, variant: "text.body.alt" }}>View Project</p>
                            <a
                                cursor-type="media-blend"
                                cursor-scale="scale-up"
                                sx={{ textDecoration: "none", variant: "text.body.main" }}
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${project.projectLink}`}
                            >
                                {project.projectLink}
                            </a>
                        </div>
                    )}
                    <div>
                        <p sx={{ pb: 1, variant: "text.body.alt" }}>Start Date</p>
                        <p sx={{ variant: "text.body.main" }}>
                            {format(project.startDate, "MMMM Do, YYYY")}
                        </p>
                    </div>
                </div>
                <Banner extend imageInfo={project.breakImage} />
            </Section>
            {project.steps.map(step => (
                <Section name={step.title}>
                    <h1 sx={{ pb: 6, variant: "text.title.main" }}>{step.title}</h1>
                    <Description>
                        <BlockText blocks={step._rawDescription} />
                    </Description>
                    {step._rawComponents.map(component => (
                        <>
                            {component._type === "listWithTitle" && (
                                <List
                                    title={component.title}
                                    list={component.components}
                                    description={component.description}
                                />
                            )}
                            {component._type === "banner" && (
                                <>
                                    {/* <Banner imageId={project.banner._rawAsset._ref} /> */}
                                    {/* <img
                                        src={imageUrlFor(buildImageObj(project.banner))
                                            .width(390)
                                            .height(346)
                                            .fit("fillmax")
                                            .url()}
                                        alt=""
                                    /> */}
                                </>
                            )}
                            {component._type === "imageGrid" && (
                                <FigureGrid list={component} showInfo center />
                            )}
                            {component._type === "caseStudyImageGrid" && (
                                <ImageGrid images={component} />
                            )}
                            {component._type === "pdfDisplay" && (
                                <PdfDisplay pdf={pdf}/>
                            )}
                        </>
                    ))}
                </Section>
            ))}
        </Layout>
    );
};

export default Projects;
