/** @jsx jsx */
import { jsx } from "theme-ui";
import Image from "gatsby-plugin-sanity-image";

const ImageGrid = ({ images }) => {
    return (
        <div
            className="project-grid"
            sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridAutoRows: "minmax(500px, auto)",
                gridGap: [1, 2, 3],
                mx: ["-10%", 0],
                mt: 6
            }}
        >
            <div sx={{ gridRow: 1, gridColumn: "1/3" }}>
                <div
                    className="img-container"
                    sx={{ width: "100%", height: "500px", bg: "#efefef" }}
                >
                    <Image
                        {...images.top}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }}
                    />
                </div>
            </div>
            <div sx={{ gridRow: 2, gridColumn: ["1/3", "1/3", "1/2"] }}>
                <div
                    className="img-container"
                    sx={{ width: "100%", height: "500px", bg: "#efefef" }}
                >
                    {" "}
                    <Image
                        {...images.left}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }}
                    />
                </div>
            </div>
            <div sx={{ gridRow: [3, 3, 2], gridColumn: ["1/3", "1/3", "2/3"] }}>
                <div
                    className="img-container"
                    sx={{ width: "100%", height: "500px", bg: "#efefef" }}
                >
                    {" "}
                    <Image
                        {...images.right}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }}
                    />
                </div>
            </div>
            <div sx={{ gridRow: [4, 4, 3], gridColumn: "1/3" }}>
                <div
                    className="img-container"
                    sx={{ width: "100%", height: "500px", bg: "#efefef" }}
                >
                    {" "}
                    <Image
                        {...images.bottom}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover"
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ImageGrid;
